<app-loading-progress-bar #progressBarModal
    [title]="title"
    [initPercent]="5"
    (taskSuccess)="_success($event)"
    (taskFailed)="_failed($event)"
></app-loading-progress-bar>

<ng-template #failedModal
  let-c="close"
  let-d="dismiss">
  <app-modal-confirmation-std4-skin [dismissText]="'COMMON.Cancel' | translate"
      [header]="'EXPORT.Download failed' | translate"
      [submitText]=" 'EXPORT.Download Again' | translate"
      [title]="'EXPORT.Oops! An error has occurred' | translate"
      [subtitle]="'EXPORT.Your program failed to download. Please try again' | translate"
      (dismiss)="c()"
      (submitted)="_tryAgain()">
    <ng-template>
      <svg class="text-blue-sapphire"
          height="56"
          width="56"
          fill="currentColor">
        <use height="56"
            width="56"
            href="assets/images/icons/alert/exclamation-circle-fill.svg#exclamation-circle-fill"
            [style.fill]="'#f53636'"></use>
      </svg>
    </ng-template>
  </app-modal-confirmation-std4-skin>
</ng-template>

