<ng-template #progressbarModal
  let-c="close"
  let-d="dismiss">
  <div class="modal-body p-3">
    <div class="full-width mx-2">
      <div [style.color]="'#1958D3'"
          [style.font-size.px]="40"
          class="col-12 text-center fw-semibold">
        <div class="pt-5">
          {{ percent }}%
        </div>
      </div>
      <div class="col-12 text-center p-3">
        {{ title | translate }}
      </div>
      <div class="pb-5 px-3">
        <ngb-progressbar [type]="'custom'"
            [value]="percent"
            height="1rem">
        </ngb-progressbar>
      </div>
    </div>
  </div>
</ng-template>