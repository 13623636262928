import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CeleryImportState,
  CeleryTaskStatus,
} from '@shared/models/drf-response.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { BatchImportService } from '@shared/components/batch-import/batch-import.service';

@Component({
  selector: 'app-loading-progress-bar',
  templateUrl: './loading-progress-bar.component.html',
  styleUrls: ['./loading-progress-bar.component.scss'],
})
export class LoadingProgressBarComponent implements OnDestroy {
  // refresh less than 1-2 secs is too much. it should be more than 5 secs.
  @Input() timeout = 5000;
  @Input() initPercent = 0;
  @Input() title = 'EXPORT.Please wait while the page is loading';

  @Output() taskSuccess = new EventEmitter();
  @Output() taskFailed = new EventEmitter();

  maxFakePercentage: number;
  fakePercent: number;
  fakeIntervalTime: number;
  fakePercentInterval: any;

  sendingTaskInterval: any;
  sendingTaskDetail: CeleryTaskStatus | any;
  taskId: string;

  subscription: Subscription[] = [];

  @ViewChild('progressbarModal', { static: true })
  progressbarModal: ElementRef;

  constructor(
    private apiService: BatchImportService,
    public modalService: NgbModal,
    private idle: Idle,
  ) {}

  showModal(taskId: string): void {
    this.taskId = taskId;
    this.clearState();
    this.subscribe(this.taskId);
    this.modalService.open(this.progressbarModal, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
  }

  clearState() {
    this.cancelSendingTask();
    this.maxFakePercentage = this.initPercent;
    this.fakeIntervalTime = this.timeout / this.maxFakePercentage;
    this.fakePercent = 0;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
  }

  subscribe(taskId: string): void {
    this.sendingTaskInterval = undefined;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
    this.fakeInterval();
    this.subscribeSendingTaskStatus(taskId);
  }

  subscribeSendingTaskStatus(taskId: string): void {
    this.sendingTaskInterval = setInterval(() => {
      // send signal to Idle object that user is using website
      //   because report may take a long to time to process.
      this.idle.interrupt();
      if (
        this.sendingTaskDetail == null ||
        (this.sendingTaskDetail._state !==
          CeleryImportState.Success &&
          this.sendingTaskDetail._state !== CeleryImportState.Failure)
      ) {
        const sub = this.apiService
          .getCeleryTaskStatus(taskId)
          .subscribe((res: any) => {
            // update progress
            this.sendingTaskDetail = res;
            // unsubscribe when success or failure
            if (res._state === CeleryImportState.Failure) {
              this.taskFailed.emit(this.sendingTaskDetail);
            } else if (res._state === CeleryImportState.Success) {
              this.fakePercent = 100;
              this.taskSuccess.emit(this.sendingTaskDetail);
            } else {
              if (res.percent === 100) {
                res.percent = 99;
                return;
              }
              if (res.percent + this.initPercent > this.percent) {
                this.maxFakePercentage = Math.min(
                  Math.max(
                    this.maxFakePercentage,
                    this.percent + this.initPercent,
                  ),
                  100,
                );
                this.fakeInterval();
              }
            }
          });
        this.subscription.push(sub);
      }
    }, this.timeout);
  }

  cancelSendingTask(): void {
    if (this.sendingTaskInterval) {
      clearInterval(this.sendingTaskInterval);
    }
    if (this.fakePercentInterval) {
      clearInterval(this.fakePercentInterval);
    }
  }

  fakeInterval() {
    if (this.fakePercentInterval) {
      clearInterval(this.fakePercentInterval);
    }
    this.fakePercentInterval = setInterval(() => {
      const currentPercent = this.percent;
      if (currentPercent + 1 >= this.maxFakePercentage) {
        clearInterval(this.fakePercentInterval);
      } else {
        this.fakePercent = currentPercent + 1;
      }
    }, this.fakeIntervalTime);
  }

  get percent() {
    return Math.min(
      Math.max(this.fakePercent, this.sendingTaskDetail.percent || 0),
      100,
    );
  }

  ngOnDestroy(): void {
    this.cancelSendingTask();
    if (this.subscription) {
      this.subscription?.forEach((item) => {
        try {
          item.unsubscribe();
        } catch (e) {
          console.error(e);
        }
      });
    }
  }
}
