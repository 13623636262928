import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '@core/http/api.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from '@shared/service/api.constant';
import { CeleryTaskStatus } from '../../../modules/manage-types-upload-memo/shared/manage-types-upload-memo.models';

@Injectable({
  providedIn: 'root',
})
export class BatchImportService {
  baseUrl = environment.baseUrl;

  constructor(
    private http: ApiService,
    private httpClient: HttpClient,
  ) {}

  getExample(type: string): Observable<HttpResponse<string>> {
    return this.httpClient.get<string>(
      this.baseUrl + API_URL.batch_uploads + type + '/',
      {
        headers: this.http.requestHeader(),
        responseType: 'blob' as 'json',
        observe: 'response',
      },
    );
  }

  import(fd: FormData, type: string) {
    return this.http.post<{ task_id: string }>(
      API_URL.batch_uploads + `${type}/`,
      fd,
    );
  }

  getCeleryTaskStatus(
    taskId: string,
    params?: any,
  ): Observable<CeleryTaskStatus> {
    return this.http.get(`/api/celery-tasks/` + taskId + '/', params);
  }
}
