import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingProgressBarComponent } from '@shared/components/loading-progress-bar/loading-progress-bar.component';
import { AlertService } from '@core/services/alert.service';
import { FileManagerService } from '@shared/service/file-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@core/http/api.service';

@Component({
  selector: 'app-export-file-modal',
  templateUrl: './export-file-modal.component.html',
  styleUrls: ['./export-file-modal.component.scss'],
})
export class ExportFileModalComponent {
  @Input() title = 'EXPORT.Downloading... Please wait a moment.';
  @Output() exportSuccess = new EventEmitter();
  @Output() exportFailed = new EventEmitter();
  @Output() tryAgain = new EventEmitter();

  @ViewChild('progressBarModal')
  progressBarModal: LoadingProgressBarComponent;
  @ViewChild('failedModal', { static: true })
  failedModal: ElementRef;

  constructor(
    public modalService: NgbModal,
    private alert: AlertService,
    private fileManagerService: FileManagerService,
    private translate: TranslateService,
    private httpClientService: ApiService,
  ) {}

  showModal(taskId: string): void {
    this.progressBarModal.showModal(taskId);
  }

  _success(event: any) {
    const filePath = event.file;
    this.download(filePath);
  }

  _failed(event: any) {
    this.modalService.dismissAll();
    this.modalService.open(this.failedModal, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
  }

  _tryAgain() {
    this.modalService.dismissAll();
    this.tryAgain.emit();
  }

  download(filePath: string) {
    const fileName = filePath.split('/').pop()?.split('?')[0];
    this.httpClientService.download(filePath).subscribe((res) => {
      const blob = new Blob([res as Blob]);
      this.fileManagerService.downloadFile(
        blob,
        fileName || 'export.csv',
      );
      this.modalService.dismissAll();
      this.alert.success(
        this.translate.instant('EXPORT.Downloaded successfully'),
      );
      this.exportSuccess.emit();
    });
  }
}
